import React from "react";

import Cards from "./Components/Cards";
import Topbar from "./Components/Topbar";
import Footer from "./Components/Footer";
import Home from "./Components/Home";



function App() {
  return (
    <div>
    <Topbar />
    <Home />
    <Cards />
    <Footer />
    </div>
  );
}

export default App;
