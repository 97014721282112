import React, {useState, useEffect} from 'react'
import emailjs from '@emailjs/browser';

const Cards = () => {
    const [showFormQuote, setShowFormQuote] = useState(false);
    const [showFormSchedule, setShowFormSchedule] = useState(false);
    const [buttonText, setButtonText] = useState('Send');

    useEffect(() => {
        emailjs.init("-RqkLwbXutHYcakGv");
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonText('Submitting...');

        emailjs.sendForm('service_b0nlzej', 'template_3ep955k', e.target)
            .then((result) => {
                console.log(result.text);
                setButtonText('Message Submitted');
                setTimeout(() => {
                    setShowFormQuote(false);
                    setShowFormSchedule(false);
                    setButtonText('Send'); // Reset button text for next form open
                }, 2000); // Close form after 2 seconds
            }, (error) => {
                console.log(error.text);
                // Optionally handle errors differently
                setButtonText('Send'); // Reset button text if there's an error
            });
    };
    // Form component (you can customize this as needed)
    const FormComponent = ({ formType, closeForm }) => (

        <div>
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${formType ? 'block' : 'hidden'}`}>
            <div className='bg-white p-5 rounded-lg max-w-sm'>
                <span className='block text-right text-xl cursor-pointer' onClick={closeForm}>&times;</span>
                <form onSubmit={sendEmail}>
                    <h3 className='text-lg font-semibold'>{formType === 'quote' ? 'GET A QUOTE' : 'GET A QUOTE'}</h3>
                    <input type="text" placeholder='Name' name='name' id='name' required className='border p-2 w-full mt-3 bg-gray-200'/>
                    <input type="email" placeholder='Email' name='email' id='email' required className='border p-2 w-full mt-3 bg-gray-200'/>
                    <input type="text" placeholder='Phone Number (optional)' name='phone' id='phone' className='border p-2 w-full mt-3 bg-gray-200'/>
                    <textarea placeholder='How can we help you?' name='message' id="message" rows="4" className='border p-2 w-full mt-3 bg-gray-200'></textarea>
                  <button type="submit" className='border p-2 mt-3 bg-[#FFA500] rounded-md font-bold w-[140px] hover:bg-[#E19100]'>{buttonText}</button>
                   
                </form>
            </div>
        </div>
        </div>
    );
  return (
    <div id='services' className='w-full py-[10rem] px bg-white text-black '>
        <div className='max-w-[840px] mx-auto grid md:grid-cols-1 gap-8'>
          
            <div className='w-s shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300  '>

            <h2 className='text-2xl font-bold text-center py-8 '>Pricing</h2>
                <p className='text-center text-4xl font-bold'></p>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8  mt-8 mb-8'>360 Photo Booth: $150 per hour plus tax</p>
                
                </div>
               
                <h2 className='text-2xl font-bold text-center py-8 '>What you get</h2>
                <p className='text-center text-4xl font-bold'></p>
                <div className='text-center font-medium pt-5'>
                    <p className='py-2 border-b mx-8  mt-8'>360 Photo Booth</p>
                    <p className='py-2 border-b mx-8'>Lighting</p>
                    <p className='py-2 border-b mx-8'>Share Station</p>
                    <p className='py-2 border-b mx-8'>Props</p>
                </div>
                <button className='bg-black text-[#FFA500] w-[250px] text-center rounded-md font-medium mx-auto my-6 py-2' onClick={() => setShowFormSchedule(true)}  >Contact Us</button>
            
            </div>
        </div>
        {showFormQuote && <FormComponent formType="quote" closeForm={() => setShowFormQuote(false)} />}
            {showFormSchedule && <FormComponent formType="schedule" closeForm={() => setShowFormSchedule(false)} />}
    </div>
  )
}

export default Cards
