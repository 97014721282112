import React, {useState} from 'react'
import logo from './logo.png';
import { FiAlignJustify } from "react-icons/fi";
import { Link } from 'react-scroll';

const Topbar = () => {

    const  [nav, setNav] = useState(false)

    const handleNav = () => 
    {
        setNav(!nav) // sets nav to the opposite of its current value
    }
  return (
    <div id='home'>
      <div className='flex justify-between items-center h24 max-w-[1300px] mx-auto px-4 text-white'>  {/* this is tailwind*/}
      <img className= 'w-[7rem] h-[7rem]' src={logo} alt="logo" />
        <ul className='hidden md:flex text-[#FFA500]'>
                    <li className='p-4'><Link to="home" smooth={true} duration={1000}>Home</Link></li>
                    <li className='p-4'><Link to="services" smooth={true} duration={1000}>Services</Link></li>
                    <li className='p-4'><Link to="contact" smooth={true} duration={1000}>Contact</Link></li>  
        </ul>
        <div onClick={handleNav} className='block: md:hidden text-[#FFA500]'>
            {nav ? < FiAlignJustify size={25} /> : <FiAlignJustify size = {25} /> }
        
            <div className= {nav ? 'fixed left-0 top-0 w-[50%] h-full border-r border-r-gray-900 bg-black ease-in-out duration-700 z-50 opacity-100'  : 'fixed left-[-100%] '} > {/* this is for mobile*/}
            <img className= 'w-[7rem] h-[7rem]' src={logo} alt="logo" />

            <ul className=' p-4 uppercase'>
                    <li className='p-4'><Link to="home" smooth={true} duration={1000}>Home</Link></li>
                    <li className='p-4'><Link to="services" smooth={true} duration={1000}>Services</Link></li>
                    <li className='p-4'><Link to="contact" smooth={true} duration={1000}>Contact</Link></li>

            </ul>
        </div>
            
        </div>

        </div>
    </div>
  )
}

export default Topbar
