import React from 'react'
import { TiSocialInstagram} from "react-icons/ti";


const Footer = () => {
  return (
    <div className='text-[#FFA500]'>
    <div className='max-w-[1240px] mx-auto pxy-16 px-4 grid  gap-8 text-[#FFA500]'>
        <div>
        <h1 className = 'w-full text-3xl font-bold '>LivScale</h1>
        <p className='py-4'>Welcome to LivScale, where we turn every event into a picture-perfect memory! We're not just a photo booth rental company; we're the life of the party. With an array of booths, backdrops, and props that defy ordinary, LivScale transforms your moments into unforgettable snapshots of pure joy. Let the good times roll with our cool, fun, and innovative photo experiences – because every event deserves a LivScale upgrade! </p>
        <div className='flex items-center justify-between my-6 '>
            
            <a className='items-center text-center' href="https://www.instagram.com/livscaleevents/" target="_blank" rel="noopener noreferrer">
              <TiSocialInstagram size={30} />
            </a>

            <div id='contact'>
                <h6 className='font-medium text-[#E19100]'>Contacts</h6>
                <ul>
                    <li className='py-2 text-sm'>info@livscale.com</li>
                    <li className='py-2 text-sm'>201-904-5003</li>
                </ul>
            </div>

        </div>
        </div>
       
    </div>
    </div>
  )
}

export default Footer
