import React, {useState, useEffect} from 'react'
import Typed from 'react-typed';
import vid1 from './Video360.mp4'
import prop from './props.png'
import icon from './icon3602.png'

const Home = () => {

  const [highlightTitle, setHighlightTitle] = useState(false);
  const [highlightSubtitle, setHighlightSubtitle] = useState(false);
  const [highlightTyper, setHighlightTyper] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;

    // Adjust these values based on your layout and where these elements are located
    if (position >= -10 && position <= 50) {
      setHighlightTitle(true);
    } else {
      setHighlightTitle(false);
    }

    if (position > 50 && position <= 200) {
      setHighlightSubtitle(true);
    } else {
      setHighlightSubtitle(false);
    }
  

  if (position > 200 && position <= 500) {
    setHighlightTyper(true);
  } else {
    setHighlightTyper(false);
  }
};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      
      <div className='text-[#E19100]'>
      {/* Flex container */}
      <div className='flex flex-col md:flex-row justify-start items-start mt-[96px] p-8 w-full h-[600px]'>
      <div className='max-w-[800px]'>
            <h1 className={`text-5xl font-bold py-3 opacity-100 hover:scale-105 duration-300 sm:hover:opacity-100 ${highlightTitle ? ' md:opacity-100 md:scale-105 duration-300' : 'md:opacity-20'}`}>
              Capturing Moments, Creating Memories!
            </h1>
            <p className={`text-2xl font-bold py-3 opacity-100 hover:scale-105 duration-300 sm:hover:opacity-100 ${highlightSubtitle ? ' md:opacity-100 md:scale-105 duration-300' : 'md:opacity-20'}`}>
              LivScale, your premier destination for top-notch photo booth experiences. Your memories, our focus.
            </p>
            <div className={`flex opacity-100 hover:scale-105 duration-300 pb-8 sm:hover:opacity-100 ${highlightTyper ? 'md:opacity-100 md:scale-105 duration-300' : 'md:opacity-20'}`}>
              <p className='text-xl font-semibold'>We do it all,&nbsp;</p>
              <Typed className='text-xl font-semibold' strings={['Weddings.', 'Corporate Events.', 'Social Gatherings.']} typeSpeed={90} backSpeed={70} loop />
            </div>
          </div>


        {/* Video bubble */}
        <div className='flex flex-row md:flex-col justify-center items-center'>
        <div className=" sm:w-60 sm:h-60 w-20 h-20 rounded-full overflow-hidden flex justify-center items-center md:ml-[-100px] md:mt-[-200px] hover:scale-105 duration-300  ">
          <video src={vid1} controls autoPlay loop muted className="w-full h-auto"></video>
        </div>
        <div className=" sm:w-60 sm:h-60  w-20 h-20 rounded-full overflow-hidden flex justify-center items-center md:ml-[100px] hover:scale-105 duration-300  ">
          <img className='w-full h-auto' src={prop} alt="prop" />
        </div>
        <div className=" sm:w-60 sm:h-60  w-20 h-20 rounded-full overflow-hidden flex justify-center items-center md:ml-[-100px] hover:scale-105 duration-300  ">
          <img className='w-full h-auto' src={icon} alt="icon" />
        </div>
        </div>
       </div>

    </div>
    </div>
   
  )
}

export default Home
